/* Genel Header Stili */
/* Header genel stilini ayarla */

@media (max-width: 768px) {
  .bottom-row {
    transition: opacity 0.5s, height 0.5s, padding 0.5s;
    height: 0; /* İlk başta yüksekliği sıfır yap */
    opacity: 0; /* İlk başta opaklığı sıfır yap */
  }

  .bottom-row.visible {
    opacity: 1; /* Gözükür yap */
    height: auto; /* Varsayılan yüksekliği belirle */
  }
}

@media (max-width: 768px) {
  .bottom-row.visible {
    height: 32px; /* Varsayılan yüksekliği belirle */
  }
}

@media (max-width: 530px) {
  .bottom-row.visible {
    height: 26px; /* Varsayılan yüksekliği belirle */
  }
}

@media (max-width: 375px) {
  .bottom-row.visible {
    height: 56px; /* Varsayılan yüksekliği belirle */
  }
}

.header-element {
  display: flex;
  flex-direction: row; /* Yatay yön */
  flex-wrap: wrap; /* İçerikler sığmadığında alt satıra geçsin */
  justify-content: space-between; /* İçerikleri sağa ve sola yasla */
  background-color: #000; /* Arka plan rengini siyah yap */
  color: #fff; /* Yazı rengini beyaz yap */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  padding: 10px 20px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 98;
  position: fixed;
  width: 100%;
}

.bottom-row {
  font-family: "Arial", sans-serif; /* Bu, Arial yazı tipini kullanacak, bulunamazsa sans-serif yedeğine geçecek */
}

.top-row {
  align-items: stretch !important; /* Tüm çocukları aynı yüksekliğe getir */
}

.header-element .top-row .nav-dropdown-language img {
  height: 24px;
  width: 32px;
  margin-right: 15px; /* Dil seçenekleri için sağ tarafta 15px boşluk */
}

.header-element {
  width: 910px;
  margin: auto;
  border-radius: 10px;
  background-color: #000000; /* Arka plan rengini siyah yap */
  color: #ffffff !important; /* Yazı rengini beyaz yap */
  border-top: 2px solid #ffffff; /* Kutunun kenar çizgisi */
  border-bottom: 2px solid #ffffff; /* Kutunun kenar çizgisi */
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-row .top-left, /* ya da .top-left */
.top-row .top-right /* ya da .top-right */ {
  flex: none; /* veya flex-grow: 0; Bu elemanlar büyümeyecek */
}

.top-row .search-container /* Orta kısımdaki arama kutusu için varsayılan */ {
  flex-grow: 1; /* Bu eleman kalan alanı dolduracak */
  margin: 2px 10px; /* Sağ ve sol boşluk */
}

@media (max-width: 530px) {
  .top-right {
    margin-top: 5px; /* Sağ ve sol boşluk */
  }
}

@media (max-width: 530px) {
  .top-row .search-container /* Orta kısımdaki arama kutusu için varsayılan */ {
    padding-bottom: 3px; /* Sağ ve sol boşluk */
  }
}

/* .top-left ve .top-right öğelerini flex öğesi olarak ayarla ve esneklik ver */
.header-element .bottom-row > .nav-item {
  flex: 1 !important; /* Flex-grow, flex-shrink ve flex-basis değerleri */
  text-align: center !important; /* Metni ortala */
}

.header-element .nav-link {
  color: #ffffff;
}

/* .top-left ve .top-right öğelerini flex öğesi olarak ayarla ve esneklik ver */
.header-element .top-left,
.header-element .top-right,
.header-element .bottom-row > .nav-item {
  flex: 1 !important; /* Flex-grow, flex-shrink ve flex-basis değerleri */
  text-align: center !important; /* Metni ortala */
}

.bottom-row {
  border: #000 solid 3px;
}

@media (max-width: 1050px) {
}

/* Açılır menüler için stil */
.dropdown-menu {
  border-top: 1px solid #fff; /* Üstte beyaz çizgi */
}

.dropdown-menu {
  font-size: 20px !important;
}

.top-row {
  display: flex;
  align-self: center;
  flex: 1 0 auto; /* Her bir satırın esneklik değerlerini ve genişliklerini ayarla */
}

.bottom-row {
  display: flex;
  justify-content: left;
  flex: 1 0 auto; /* Her bir satırın esneklik değerlerini ve genişliklerini ayarla */
  gap: 5%;
}

.top-left {
  display: flex;
  align-items: center; /* İçerikleri dikey olarak ortalar */
}

.top-left .nav-link {
  border: 0.1rem solid #ffffff !important;
  border-radius: 10px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

@media (min-width: 768px) {
  .logo {
    margin-right: 30px; /* Logo ile diğer öğeler arasında boşluk ekler */
    margin-left: 30px; /* Logo ile diğer öğeler arasında boşluk ekler */
    height: 42px;
  }
}

.logo {
  margin-right: 20px; /* Logo ile diğer öğeler arasında boşluk ekler */
  height: 42px;
}
@media (max-width: 768px) {
  .logo {
    margin-right: 20px; /* Logo ile diğer öğeler arasında boşluk ekler */
    height: 32px;
  }
}

@media (max-width: 530px) {
  .logo {
    margin-right: 20px; /* Logo ile diğer öğeler arasında boşluk ekler */
    height: 26px;
  }
}

/* Nav.Link Stili */
.header-element .nav-link {
  color: #fff;
  text-decoration: none;
  padding: 2px;
  transition: background-color 0.3s ease-in-out;
}

/* Nav.Link hover ve focus durumları için Stil */
.header-element .nav-link:hover,
.header-element .nav-link:focus {
  background-color: #f0f0f0; /* Açık arka plan */
  color: #000; /* Yazı rengini karart */
}

/* Açılır Menü Stili */
.header-element .dropdown-menu {
  background-color: black; /* Menü arka plan rengi */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  margin-top: 14px;
  color: #ffffff;
}

@media (max-width: 530px) {
  .header-element .dropdown-menu {
    margin-top: 6px;
  }
}

/* Dropdown Menü Öğesi Stili */
/* Açılır Menü Öğesi Stili - Üst menü stilini uygula */
.header-element .dropdown-item {
  color: #fff; /* Yazı rengini beyaz yap */
  text-decoration: none; /* Alt çizgiyi kaldır */
  padding: 0.1rem 0.5rem; /* Üst ve yan padding */
  display: block; /* Dropdown öğelerini blok olarak ayarla */
  width: 100%; /* Tam genişlik - Padding dahil */
  text-align: center; /* Metni ortala */
  border-radius: 10px; /* Kenar yuvarlaklığını uygula */
  transition: background-color 0.3s ease-in-out; /* Geçiş efektini uygula */
}

/* Açılır menü stilini ayarla */
.header-element .dropdown-menu {
  z-index: 99; /* Header z-index'inden yüksek olmalı */
}
/* Dropdown Toggle Stili */
.header-element .nav-item .dropdown-toggle {
  border: none; /* Kenarlık kaldır */
}
/* Dropdown Menü Aktif Olduğunda Arka Plan ve Yazı Rengini Ayarla */
.header-element .nav-item.show .dropdown-menu,
.header-element .nav-item.show .dropdown-toggle {
  background-color: #000 !important; /* Arka planı tekrar siyah yap */
  color: #fff !important; /* Yazı rengini beyaz yap */
}

/* Nav.Link üzerine geldiğinde veya odaklandığında ve aktifken arka planı değiştir */
.header-element .nav-link:hover,
.header-element .nav-link:focus,
.header-element .nav-link:active {
  /* Aktif durum eklendi */
  background-color: #333; /* Arka planı daha koyu bir renge çevir */
  color: #fff; /* Yazı rengini beyaz yap */
}

/* Dropdown öğesi üzerine geldiğinde veya odaklandığında ve aktifken arka planı değiştir */
.header-element .dropdown-item:hover,
.header-element .dropdown-item:focus,
.header-element .dropdown-item:active {
  /* Aktif durum eklendi */
  background-color: #333; /* Arka planı daha koyu bir renge çevir */
  color: #fff; /* Yazı rengini beyaz yap */
}

/* Dropdown toggle üzerine geldiğinde veya odaklandığında ve aktifken stil */
.header-element .nav-item .dropdown-toggle:hover,
.header-element .nav-item .dropdown-toggle:focus,
.header-element .nav-item .dropdown-toggle:active {
  /* Aktif durum eklendi */
  background-color: #333; /* Arka planı koru */
  color: #fff; /* Yazı rengini beyaz yap */
}

.nav-tabs .nav-link {
  border-radius: 10px;
}

@media (max-width: 900px) {
  .header-element {
    width: 100vw; /* Ekran genişliğinin %85'i kadar */

    font-size: 20px; /* Üst menü için font boyutu */
  }

  .dropdown-menu {
    font-size: 18px !; /* Açılır menü için font boyutu */
  }
}

@media (max-width: 768px) {
  .header-element {
    font-size: 18px; /* Üst menü için font boyutu */
  }

  .dropdown-menu {
    font-size: 16px !; /* Açılır menü için font boyutu */
  }
}

@media (max-width: 530px) {
  .header-element {
    font-size: 14px; /* Üst menü için font boyutu */
  }
  .bottom-row .nav-link,
  .bottom-row .dropdown-toggle {
    font-size: 14px; /* Alt satır için font boyutu */
  }

  .dropdown-item {
    font-size: 14px;
  }
}

@media (max-width: 434px) {
  .header-element {
    flex-direction: column; /* İçerikleri dikey eksende sırala */
    padding: 7px;
  }

  .logo {
    margin-right: 10px; /* Logo ile diğer öğeler arasında boşluk ekler */
  }

  .bottom-row {
    padding-top: 1px; /* Üst satırdan boşluk kaldır */
    flex-wrap: wrap; /* İçerikler sığmadığında alt satıra geçsin */
    gap: 0px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-item {
    font-size: 14px;
  }
}

@media (max-width: 350px) {
  .bottom-row {
    justify-content: flex-start; /* Merkeze hizala */
    padding-top: 0; /* Üst satırdan boşluk kaldır */
    flex-wrap: wrap; /* İçerikler sığmadığında alt satıra geçsin */
    gap: 0px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-item {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .dropdown-menu {
    /* Açılır menü pozisyonunu ayarla */
    position: absolute !important;
    left: 10% !important; /* Sağa sola eşit miktarda yer bırak */
  }
}
@media (max-width: 768px) {
  .header-element .bottom-row .nav-item.dropdown {
    max-height: 0px;
  }
}
