/* TwitterFollow.css */

.twitter-follow-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7); /* Daha koyu bir overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.twitter-follow-modal-content {
  background: linear-gradient(
    145deg,
    #ffffff,
    #f7f7f7
  ); /* Hafif bir degrade arka plan */
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  max-width: 450px;
  width: 90%;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); /* Daha belirgin gölge */
  position: relative;
  font-weight: 600;
}

.twitter-follow-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #ff5c5c; /* Çarpı düğmesi için kırmızı */
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.twitter-follow-close-button:hover {
  background: #ff3333;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0;
  gap: 10px;
  font-size: 16px;
  text-decoration: none;
  color: #333;
  background: #f0f0f0; /* Linkler için arka plan */
  padding: 10px 15px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.social-link:hover {
  color: white;
  background: #0073e6; /* Hover rengini düzenledik */
}

.twitter-follow-twitter-span {
  color: #1da1f2; /* Twitter mavi */
}

.twitter-follow-instagram-span {
  color: #d6249f; /* Instagram pembe */
}

.twitter-follow-youtube-span {
  color: #ff0000; /* YouTube kırmızı */
}

.twitter-follow-twitter-logo,
.twitter-follow-instagram-logo,
.twitter-follow-youtube-logo {
  width: 24px;
  height: 24px;
}
