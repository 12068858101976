:root {
  --footer-height: 200px;
}

/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400; /* Metinlerin daha hafif ve okunabilir olması için */
  line-height: 1.6; /* Metinler arası okunabilirliği artırmak için satır yüksekliği */
  color: #333; /* Metin rengi olarak koyu gri, kontrast ve okunabilirlik için */
  background-color: #fff; /* Arka plan rengi beyaz */
  max-width: 100vw; /* Viewport genişliğine göre maksimum genişlik */
  min-height: 100vh; /* Viewport yüksekliğine göre minimum yükseklik */
  position: relative;
  box-sizing: border-box; /* Padding ve border, genel boyutu etkilemez */
  overflow-x: hidden; /* Yatay yönde taşan içeriği gizle */
  padding: 0;
}

a:any-link {
  text-decoration: none;
}

/* Main layout styles */
main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: var(--footer-height);
  height: 100%;
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 7.5rem;
}

@media (max-width: 1000px) {
  main {
    margin-top: 7.5rem; /* Mobil için üst margini artır */
  }
}

@media (max-width: 768px) {
  main {
    margin-top: 5.5rem; /* Mobil için üst margini artır */
  }
}

@media (max-width: 490px) {
  main {
    margin-top: 5.5rem; /* Mobil için üst margini artır */
  }
}

@media (max-width: 375px) {
  main {
    margin-top: 7rem; /* Mobil için üst margini artır */
  }
}

@media (min-width: 1600px) {
  .aside-left-470-440,
  .aside-right-470-440 {
    position: fixed; /* Sabit konumlandırma */
    width: 300px;
    top: 615px; /* Üstten 120px boşluk */
    height: 600px;
    z-index: 6;
  }

  .aside-right-470-440 {
    right: 10px;
  }
  .aside-left-470-440 {
    left: 10px;
  }
}

@media (min-width: 1700px) and (max-width: 1850px) {
  .aside-left-470-440,
  .aside-right-470-440 {
    width: 300px;
  }
}

@media (min-width: 1850px) {
  .aside-left-470-440,
  .aside-right-470-440 {
    position: fixed; /* Sabit konumlandırma */
    width: 470px;
    top: 615px; /* Üstten 120px boşluk */
    height: 440px;
    z-index: 6;
  }

  .aside-left-470-440 {
    left: 10px;
  }

  .aside-right-470-440 {
    right: 10px;
  }
}

@media (min-width: 1700px) and (max-width: 1850px) {
  .aside-left-160-600,
  .aside-right-160-600 {
    display: none; /* Bu ekran genişliği aralığında reklamları gizle */
  }
}

@media (min-width: 1850px) {
  .aside-left-300-600,
  .aside-right-300-600 {
    position: fixed; /* Sabit konumlandırma */
    width: 300px;
    top: 10px; /* Üstten 120px boşluk */
    height: 600px;
    z-index: 6;
  }

  .aside-left-300-600 {
    left: 10px;
  }

  .aside-right-300-600 {
    right: 10px;
  }

  /* 160 X 600 */
  .aside-left-160-600,
  .aside-right-160-600 {
    position: fixed; /* Sabit konumlandırma */
    width: 160px;
    top: 10px; /* Üstten 120px boşluk */
    height: 600px;
    z-index: 6;
  }

  .aside-right-160-600 {
    right: 320px;
  }
  .aside-left-160-600 {
    left: 320px;
  }
}

@media (min-width: 1600px) {
  .aside-left-300-600,
  .aside-right-300-600 {
    position: fixed; /* Sabit konumlandırma */
    width: 300px;
    top: 10px; /* Üstten 120px boşluk */
    height: 600px;
    z-index: 6;
  }

  .aside-right-300-600 {
    right: 10px;
  }
  .aside-left-300-600 {
    left: 10px;
  }
}

@media (min-width: 1300px) and (max-width: 1700px) {
  .aside-left-160-600,
  .aside-right-160-600 {
    position: fixed; /* Sabit konumlandırma */
    width: 160px;
    top: 10px; /* Üstten 120px boşluk */
    height: 600px;
    z-index: 6;
  }

  .aside-right-160-600 {
    right: 20px;
  }
  .aside-left-160-600 {
    left: 20px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

aside {
  display: block;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

aside {
  display: block;
}
.chart-container {
  display: block !important; /* Blok öğe olarak ayarla */
  border-radius: 10px;
  background-color: black;
  padding: 10px 15px; /* İç boşluk */
  padding-left: 5px;
}

.stockChartContainer {
  width: 700px;
  height: 410px;
  font-weight: 1600 !important;
  border-radius: 10px; /* Köşeleri yuvarlaklaştır */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
}

.stockChartContainer2 {
  width: 100%;
  height: 75vh;
  font-weight: 1600 !important;
  border-radius: 10px; /* Köşeleri yuvarlaklaştır */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 10px;
  margin-bottom: 60px;
}

.stockChartContainer .autoScaleButton {
  display: block; /* Blok öğe olarak ayarla */
  margin-left: 10px;
  background-color: #388e3c; /* Daha koyu yeşil arka plan */
  color: #ffffff; /* Beyaz yazı rengi, yeterli kontrasta sahip olmalı */
  padding: 10px 20px; /* İç boşluk */
  border: none; /* Kenarlık kaldırıldı */
  cursor: pointer; /* İmleç buton üzerine geldiğinde el simgesi */
  border-radius: 5px; /* Köşeleri yuvarlaklaştır */
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2); /* Gölge efekti */
  margin: 10px auto; /* Dış boşluk, yatayda otomatik ortalamak için */
  padding-bottom: 10px; /* Dış boşluk */
}

.stockChartContainer .autoScaleButton:hover {
  background-color: #2e7d32; /* Buton üzerine gelindiğinde daha koyu arka plan rengi */
}

.buttonContainer {
  display: flex; /* Flex container olarak ayarla */
  justify-content: center; /* İçerikleri yatay olarak ortala */
  align-items: center; /* İçerikleri dikey olarak ortala */
  gap: 10px; /* Butonlar arasında boşluk */
}

.ustBosluk {
  padding-top: 20px;
  z-index: 1;
}

@media (max-width: 700px) {
  .stockChartContainer {
    width: 100%;

    height: calc(
      100vw * (300 / 750)
    ); /* Genişliğin oranına göre yükseklik hesaplar */
    z-index: 0; /* Diğer öğelerin üzerinde */
  }
  .ustBosluk {
    padding-top: 100px;
    z-index: 1;
  }
  h3.ustBosluk {
    margin-top: -15px !important;
    text-decoration: underline;
  }
}

@media (max-width: 535px) {
  .stockChartContainer {
    width: 99%;

    height: calc(
      100vw * (295 / 400)
    ); /* Genişliğin oranına göre yükseklik hesaplar */
    z-index: 0; /* Diğer öğelerin üzerinde */
  }
  .ustBosluk {
    padding-top: 130px;
    z-index: 1;
  }

  .stockChartContainer2 {
    width: 105%;
    z-index: 0; /* Diğer öğelerin üzerinde */
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: min(910px, 90%);
}

.DisqusComments {
  flex: 1; /* Flex öğesinin tüm kullanılabilir alanı kaplamasını sağlar */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.comment__footer .author,
.comment__header .author {
  color: red !important;
}
.publisher-anchor-color a {
  color: rgb(255, 0, 0) !important;
}

p {
  font-size: 14px;
}

.content {
  text-align: center;
  padding: 10px;
  margin: 10px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.content li,
mark,
b,
p {
  font-size: 16px; /* Metin boyutu */
  line-height: 1.6; /* Satır yüksekliği */
  margin-bottom: 10px; /* Liste öğeleri arası boşluk */
  color: #333; /* Metin rengi */
  font-family: "Open Sans", Arial, sans-serif; /* Okunabilir bir font */
  text-align: justify; /* Metni her iki kenara yasla, seçime bağlı */
  max-width: 80ch; /* Satır uzunluğunu sınırlandırır */
}

ul > li,
ol > li {
  font-size: 18px; /* Metin boyutu */
  line-height: 1.6; /* Satır yüksekliği */
  margin-bottom: 10px; /* Liste öğeleri arası boşluk */
  color: #333; /* Metin rengi */
  font-family: "Open Sans", Arial, sans-serif; /* Okunabilir bir font */
  max-width: 80ch; /* Satır uzunluğunu sınırlandırır */
}

.responsibility {
  margin: 10px 0;
  font-size: 1.3rem;
}

/* Image container styles */
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 90%;
}

.image-item {
  margin: 15px;
  flex-basis: calc(33.333% - 30px);
  text-align: center;
}

.image-item img {
  max-width: 100%; /* Made images responsive */
  height: auto;
}

.image-item p {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.hisseYorumSpan,
.hisseFiyatSpan {
  background-color: #000000; /* Arka plan rengini siyah yap */
  color: #ffffff; /* Yazı rengini beyaz yap */
  border-radius: 15px; /* Kenarları yuvarlak yap */
  text-align: center;
  padding: 5px 10px; /* Üst ve alt için 5px, sağ ve sol için 10px dolgu */
  font-size: 15px;
  display: inline-block; /* Elementleri blok gibi göster ve yan yana getir */
  margin: 4px 0; /* Üst ve altına küçük bir boşluk ekle */
}

/* Eğer ayrı stiller istiyorsanız, bu bölümleri ayırabilirsiniz. */
.hisseYorumSpan {
  background-color: #e2dede; /* Arka plan rengini siyah yap */
  color: rgb(255, 0, 0); /* Yazı rengini beyaz yap */
  font-weight: 500;
}

.hisseFiyatSpan {
  background-color: #e2dede; /* Arka plan rengini siyah yap */
  color: rgb(255, 0, 0); /* Yazı rengini beyaz yap */
  font-weight: 500;
}
/* Twitter follow styles */
.twitter-follow {
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
}

.twitter-follow a {
  text-decoration: none;
  color: #0078d4;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  margin: 10px 0; /* Adjusted margin for spacing */
}

.data-table-container {
  overflow-x: auto;
  max-width: 100%;
}

.data-table table {
  border-collapse: collapse;
  width: 50%;
  margin: 1rem auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
}

.data-table table th {
  background-color: #0d47a1;
  color: #fff;
  padding: 5px 10px;
  text-align: center;
  border-top: 1px solid #0d47a1;
  border-bottom: 1px solid #0d47a1;
}

.data-table table tr {
  padding: 2px 0;
}

.data-table table td {
  padding: 5px 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.data-table table tr:last-child td {
  border-bottom: none;
}

/* CSS: components/CookieConsent.css */

.cookie-consent-overlay {
  position: fixed;
  bottom: 0;
  width: 100%; /* Tam genişlik */
  background-color: rgba(0, 0, 0, 0.7); /* Yarı şeffaf arka plan */
  z-index: 1000; /* Diğer öğelerin üzerinde */
  padding: 16px 0; /* Üstte ve altta padding */
  box-sizing: border-box;
}

.cookie-consent-container {
  margin: 0 16px; /* Sağ ve solda margin ile içeri boşluk verin */
  background-color: white;
  color: #333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Gölge efekti */
}

.cookie-consent-container h2 {
  font-size: 1.5rem;
  margin: 16px 0;
  padding: 0 20px; /* Başlık için sağ ve sol padding */
}

.cookie-consent-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 0 16px 0;
  padding: 0 20px; /* Paragraf metni için sağ ve sol padding */
}

.cookie-types {
  padding: 0 20px; /* Çerez türleri için sağ ve sol padding */
  margin-bottom: 16px;
}

.cookie-types div {
  margin-bottom: 8px;
}

.cookie-types input[type="checkbox"] {
  margin-right: 8px;
}

.cookie-consent-container button {
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 1rem;
  background-color: #0055ff; /* Mavi arka plan */
  color: white; /* Beyaz metin */
  transition: background-color 0.3s ease;
  margin-bottom: 16px; /* Düğmeler için altta boşluk */
}

.cookie-consent-container button:hover {
  background-color: #003399; /* Daha koyu mavi */
}

/* Responsive tasarım için ek ayarlar */
@media (max-width: 768px) {
  .cookie-consent-container {
    margin: 0 8px; /* Mobil cihazlarda kenarlara daha az Padding sağlar */
  }
  .cookie-consent-container h2 {
    font-size: 1.25rem;
  }
  .image-item {
    flex-basis: calc(50% - 30px);
  }

  .data-table table {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .image-item {
    flex-basis: calc(100% - 30px);
  }

  .content > img,
  .content > h2 {
    width: 90%;
  }
  section img,
  section h2 {
    width: 100%;
  }
}

/* Section styles */
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: min(1200px, 95%);
}

/* Genel Başlık Stilleri */
h1,
h2,
h3,
h4,
h5 {
  color: #333; /* Başlık metin rengi, koyu gri daha okunabilir olur */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Font ailesi */
  text-align: center; /* Metni ortala */
  margin-top: 20px; /* Üstten boşluk */
  margin-bottom: 20px; /* Alttan boşluk */
}

/* H1 Başlığı için Özel Stil */
h1 {
  font-size: 1.75rem; /* Daha zarif bir boyut */
  font-weight: 600; /* Orta kalınlıkta */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Hafif gölge, beyaz arka plana uygun */
}

/* H2 Başlığı için Özel Stil */
h2 {
  font-size: 1.5rem;
  font-weight: 600; /* Daha ince kalınlık */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* H3 Başlığı için Özel Stil */
h3 {
  font-size: 1.25rem;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* H4 Başlığı için Özel Stil */
h4 {
  font-size: 1.1rem;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

h5 {
  font-size: 1.1rem;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

section {
  background-color: #ffffff; /* Kutunun arka plan rengi */
  border: 4px solid #000000; /* Kutunun kenar çizgisi */
  border-radius: 8px; /* Köşeleri yuvarlak yapmak için */
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.5); /* Kutuya gölge eklemek için */
  margin: 10px 10px; /* Her section arası boşluk */
  padding: 10px; /* İçerik ve kutu kenarı arası boşluk */
  box-sizing: border-box; /* Padding ve border'ı genişliğe dahil etmek için */
}

.Giris {
  margin: auto !important; /* Sayfayı yatayda ortala */

  background-color: #ffffff; /* Kutunun arka plan rengi */
  border: 4px solid #000000; /* Kutunun kenar çizgisi */
  border-radius: 8px; /* Köşeleri yuvarlak yapmak için */
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.5); /* Kutuya gölge eklemek için */
  margin: 0px 0px; /* Her section arası boşluk */

  padding: 10px; /* İçerik ve kutu kenarı arası boşluk */
  box-sizing: border-box; /* Padding ve border'ı genişliğe dahil etmek için */
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.outermost {
  background-color: #ffffff; /* Kutunun arka plan rengi */
  border: 4px solid #000000; /* Kutunun kenar çizgisi */
  border-radius: 8px; /* Köşeleri yuvarlak yapmak için */
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.5); /* Kutuya gölge eklemek için */
  margin: 0px 0px; /* Her section arası boşluk */
  padding: 10px; /* İçerik ve kutu kenarı arası boşluk */
  box-sizing: border-box; /* Padding ve border'ı genişliğe dahil etmek için */
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 768px) {
  .outermost {
    width: 105%;
    max-width: 900px;
  }
}
@media (min-width: 768px) {
  .outermost {
    width: 855px;
    max-width: 900px;
  }
}

.Header-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: min(1200px, 95%);
  width: 910px;
  background-color: #ffffff; /* Kutunun arka plan rengi */
  border: 4px solid #000000; /* Kutunun kenar çizgisi */
  border-radius: 8px; /* Köşeleri yuvarlak yapmak için */
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.5); /* Kutuya gölge eklemek için */
  margin: 10px 10px; /* Her section arası boşluk */
  padding: 10px; /* İçerik ve kutu kenarı arası boşluk */
  box-sizing: border-box; /* Padding ve border'ı genişliğe dahil etmek için */
}

.swg-button-v2-dark {
  width: 185px !important;
  min-width: 160px !important;
  border-radius: 20px !important;
}

.SubscribeContainer {
  width: 850px; /* Kapsayıcının genişliği */
  position: relative; /* Pozisyonlamayı sağlamak için */
  margin: 0 auto; /* Sayfa ortasında hizalamak için */
}

.Subscribe {
  position: fixed; /* Sabit pozisyon */
  left: 50%; /* Sol taraftan ekranın yarısına kadar */
  transform: translateX(-50%); /* Kendi genişliğinin yarısı kadar sola kaydır */
  z-index: 1005; /* Diğer içeriklerin üzerinde görünmesini sağlar */
}

@media (max-width: 900px) {
  .SubscribeContainer {
    width: 100%; /* Kapsayıcının genişliğini ekrana sığdır */
    bottom: 30px;
  }

  .Subscribe {
    left: 50%; /* Sol taraftan ekranın yarısına kadar */
    transform: translateX(
      -50%
    ); /* Kendi genişliğinin yarısı kadar sola kaydır */
  }
}

/* ScrollToTopButton.css */
.scroll-to-top-button {
  position: fixed;
  right: 20px;
  bottom: 50%;
  background-color: #007bff; /* Parlak mavi */
  color: white;
  border: none;
  border-radius: 50%; /* Daire şeklinde */
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Gölge efekti */
  transition: background-color 0.3s, transform 0.3s; /* Renk ve transform geçiş efekti */
  z-index: 1001;
}

.scroll-to-top-button:hover {
  background-color: #0056b3; /* Daha koyu bir mavi */
  transform: scale(1.1); /* Hover'da büyütme efekti */
}

.company-info p,
h2 {
  margin-bottom: 0px;
  color: #333;
  text-align: center;
}

.algoritma-info img {
  width: 100%;
  padding: 5px;
}
section p {
  padding-left: 20px;
  padding-right: 20px;
}

section img {
  width: 100%;
  height: auto;
}

.responsive-image {
  max-width: 400px;
  height: auto;
}

/* Ortak tablo stili */
.financials-table {
  margin: 20px;
  border-collapse: collapse;
  overflow-x: auto; /* Yatay kaydırma çubuğu eklemek için */
  font-weight: 500;
}

/* Genişliği %100 olan tablo */
.financials-table.full-width {
  width: 100%;
}

/* Genişliği içeriğe göre olan tablo */
.financials-table.auto-width {
  width: auto;
}

.financials-table h2 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.financials-table th,
.financials-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.financials-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.financials-table tr:hover {
  background-color: #f1f1f1;
}

/* Tablo başlık hücreleri */
.financials-table th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

/* Alternatif satır renklendirme */
.financials-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Açık gri */
}

.financials-table tr:nth-child(odd) {
  background-color: #e0f7fa; /* Açık mavi */
}

/* Satır üzerine gelindiğinde arka plan rengini değiştir */
.financials-table tr:hover {
  background-color: #b2ebf2; /* Hover etkisi için daha koyu mavi */
}

/* Tab başlıkları için temel stiller */
.tab-titles {
  display: flex;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
  overflow-x: auto; /* Yatay kaydırma çubuğu eklemek için */
  max-width: 100%;
  font-size: 24px; /* Font boyutunu büyüt */
  color: #333333; /* Koyu gri renk */
  font-weight: bold; /* Kalın yazı */
  text-align: center; /* Ortala */
  padding: 10px 0; /* Başlık ile üst ve alt içerik arasında boşluk */
  background-color: #f7f7f7; /* Açık gri arka plan rengi */
  border-radius: 8px; /* Kenarları yuvarlak yap */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Hafif bir gölge */
  text-transform: uppercase; /* Harfleri büyük yap */
  letter-spacing: 1.2px; /* Harfler arası boşluk */
  font-weight: 600 !important;
}

.tab-titles a {
  color: black; /* Bağlantı rengi */
  text-decoration: none; /* Alt çizgiyi kaldırmak için */
}

.tab-titles a:hover {
  color: blue; /* Hover durumunda renk (isteğe bağlı) */
  text-decoration: underline; /* Hover durumunda alt çizgi eklemek için (isteğe bağlı) */
}

.tab-titles button {
  background: none;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 3px solid transparent;
  transition: all 0.3s ease;
  font-weight: 600 !important;
  font-family: "Roboto", sans-serif; /* Modern bir sans-serif yazı tipi */
}

.tab-titles button.active {
  border-bottom: 3px solid #007bff;
  font-weight: bold;
  color: #007bff;
}

.tab-titles button:hover {
  color: #0056b3;
}

/* Tab içerikleri için stil */
.tab-content {
  display: none;
  width: 100%;
}

.tab-content.active {
  display: block;
}

/* Section styles */
.tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.separator {
  border-top: 2px solid #000000; /* Çizgi rengi ve kalınlığı */
  margin: 20px 0; /* Üst ve alt boşluklar */
  box-shadow: 0 -3px 20px rgba(0, 0, 0, 0.3); /* Gölgelendirme */
}

h2 {
  scroll-margin-top: 280px; /* Header yüksekliğine uygun bir değer belirleyin */
}

section img {
  padding-top: 10px;
  padding-bottom: 10px;
}

.chart-container {
  display: flex !important;
  flex-wrap: wrap; /* Grafikleri bir sonraki satıra taşımak için */
  justify-content: center; /* Grafikleri ortalamak için */
  gap: 10px; /* Grafikler arasında 10px boşluk bırak */
}

.chart-wrapper {
  max-width: 400px; /* Grafiklerin maksimum genişliği 400px */
  width: 100%; /* Grafiklerin genişliğini tam genişlik olarak ayarla */
  flex: 1 1 calc(50% - 10px); /* Aynı satırda iki grafik yerleştirmek için */
  border: 2px solid white; /* Grafikler arasına beyaz kenarlık ekle */
  border-radius: 10px; /* Köşeleri yuvarlak yapmak için */
  padding: 10px; /* Kenarlık ile grafik arasında boşluk bırakmak için */
}

@media (max-width: 800px) {
  .chart-wrapper {
    flex: 1 1 100%; /* Daha küçük ekranlarda grafikleri tam genişlikte göster */
  }
}

/* Genel scrollbar stili */
.tab-titles {
  overflow-x: scroll; /* Her zaman kaydırma çubuğu göster */
  scrollbar-width: thin; /* Firefox için kalın kaydırma çubuğu */
  scrollbar-color: #007bff #e0e0e0; /* Thumb ve track renkleri */
}

/* WebKit tabanlı tarayıcılar (Chrome, Safari, Edge) için */
.tab-titles::-webkit-scrollbar {
  height: 16px; /* Yatay kaydırma çubuğunun yüksekliği */
  background-color: #e0e0e0; /* Track arka plan rengi */
}

.tab-titles::-webkit-scrollbar-thumb {
  background-color: #007bff; /* Thumb için dikkat çekici bir mavi renk */
  border-radius: 10px; /* Thumb köşelerini yuvarlat */
  border: 3px solid #e0e0e0; /* Thumb çevresinde boşluk ve track ile kontrast */
}

.tab-titles::-webkit-scrollbar-track {
  background-color: #e0e0e0; /* Track arka plan rengi */
}

/* Hover durumunda kaydırma çubuğu daha belirgin hale gelsin */
.tab-titles::-webkit-scrollbar-thumb:hover {
  background-color: #0056b3; /* Thumb hover rengini koyulaştır */
}

/* Mobil cihazlar için (özellikle Android tarayıcılar) */
@media (max-width: 767px) {
  .tab-titles {
    overflow-x: scroll; /* Mobil cihazlarda da kaydırma çubuğunu sürekli göster */
    scrollbar-width: thick; /* Mobil tarayıcılarda kalın kaydırma çubuğu */
    scrollbar-color: #007bff #e0e0e0; /* Thumb ve track renkleri */
  }
}

.tableInput {
  width: 50%; /* Genişliği tam yap */
  border: 1px solid #ccc; /* Çerçeve rengi */
  border-radius: 5px; /* Köşeleri yuvarlak yap */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
  transition: all 0.3s ease; /* Hover efektinde geçiş efekti */
  margin-left: 0px !important;
  margin-right: 5px;
}

.tableInput:focus {
  border-color: #007bff; /* Odaklandığında çerçeve rengini mavi yap */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5); /* Odaklandığında gölgeyi artır */
  outline: none; /* Varsayılan outline'ı kaldır */
}

@media (max-width: 767px) {
  .tableInput {
    width: 200px !important;
  }
}

.notificationBar {
  position: fixed;
  bottom: 40px;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  border-top: 2px solid #007bff;
  padding: 10px;
  display: flex;
  justify-content: center; /* İçeriği yatay olarak ortalar */
  align-items: center; /* İçeriği dikey olarak ortalar */
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  z-index: 1000;
}

.closeButton {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #007bff;
  padding: 5px;
  margin-left: 10px; /* Kapatma butonuna soldan boşluk ekler */
}

.closeButton:hover {
  color: #0056b3;
}

/* Yorum alanı genel stil */
.comment-section {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.comment-form {
  margin-bottom: 30px;
}

.comment-form h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.comment-form input,
.comment-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
}

.comment-form textarea {
  min-height: 100px;
  resize: vertical;
}

.comment-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
}

.comment-form button:hover {
  background-color: #0056b3;
}

/* Yorum listesi ve yorum elemanları */
.comment-section {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  margin-top: 10px;
}

.comment-section h3 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.comment-section form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.comment-section input,
.comment-section textarea {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
}
.comment-section textarea {
  min-height: 120px;
}

.comment-section textarea::placeholder {
  color: #aaa; /* Placeholder için silik görünüm */
}

.comment-section button {
  padding: 10px 15px;
  background-color: #0070f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.comment-section button:hover {
  background-color: #005bb5;
}

.comment {
  display: flex;
  flex-direction: column;
}

.comment-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.comment-header strong {
  font-size: 16px;
  color: #0077ff;
  margin-left: 20px;
}

.comment-time {
  font-size: 12px;
  color: #666;
}

.comment-section .loading {
  color: #0070f3;
}

.comment-section .error {
  color: red;
}

@media (max-width: 768px) {
  img {
    max-width: 450px; /* Mobil için maksimum genişlik */
  }
}

@media (min-width: 768px) {
  img {
    max-width: 800px; /* Masaüstü için maksimum genişlik */
  }
}
